/**
 * Participant status
 */
export const enum ParticipantStatus {
    LOGGED_OUT = 'LOGGED-OUT',
    ELIGIBLE = 'ELIGIBLE',
    NON_ELIGIBLE = 'NON-ELIGIBLE',
    TURNOVER_REQUIRED = 'TURNOVER-REQUIRED',
    DEPOSIT_REQUIRED = 'DEPOSIT-REQUIRED',
    ACTIVE_FIRST_QUESTION = 'ACTIVE-FIRST-QUESTION',
    ACTIVE_VALID_DEPOSIT = 'ACTIVE-VALID-DEPOSIT',
    ACTIVE = 'ACTIVE',
    GAME_EXPIRED = 'GAME-EXPIRED',
    WINNER = 'WINNER',
    LOSER = 'LOSER',
    PAID = 'PAID',
}

/**
 * Question status
 */
export const enum QuestionStatus {
    ACTIVE = 'active',
    VOID = 'void',
    SUSPENDED = 'suspended',
    SETTLED = 'settled',
}

/**
 * Key to get access to guide completed
 */
export const MILLION_STORAGE_KEY_GUIDE_COMPLETED: OBR.Storage.Key =
    'Offers/Million/guide_completed'

/**
 * Key to get access to the nuber of question which was lastly seen as due
 */
export const MILLION_STORAGE_KEY_DUE_QUESTION: OBR.Storage.Key =
    'Offers/Million/due'

/**
 * Key to get access to the nuber of question which was lastly passed
 */
export const MILLION_STORAGE_KEY_QUESTION_PASSED: OBR.Storage.Key =
    'Offers/Million/passed'

/**
 * Days until game expired after start
 */
export const MILLION_DAYS_TO_FINISH: number = 60

/**
 * Days until game expired after start
 */
export const MILLION_DAYS_TO_FINISH_OPT_IN: number = 30

/**
 * Version of game UI
 */
export const enum UiVersion {
    V1 = 'v1',
    V2 = 'v2',
}
