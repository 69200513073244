import { getMatchedModules } from '@obr-core/helpers/i18n.helpers'
import { I18nService } from '@obr-core/services/I18nService'
import { getItem, setItem } from '@obr-core/lib/storage.manager'
import {
    STORAGE_KEY_CURRENT_ROUTE,
    STORAGE_KEY_HR_REDIRECT,
} from '@obr-core/config/storage'
import {
    userStoreService,
    i18nStoreService,
    appStoreService,
    uiStoreService,
} from '@obr-core/services/store'
import { getReleaseInfo } from '@obr-core/helpers/release-info.helpers'
import { useStreamsPlayer } from '@obr-ui/components/Streams/composable'
import { OBRDocument } from '@obr-core/helpers'
import { getHostUri } from '@obr-core/helpers/brand-settings.helpers'
import { isIframe } from '@obr-core/helpers/app.helpers'
import { ENV_RB, RBEnv } from '@obr-core/config/env'
import {
    APP_QUERY_PARAM_REDIRECT,
    APP_TEST_URLS,
} from '@obr-core/config/iframe-ui'

const { onOpen } = useStreamsPlayer()
/**
 * Global before guards are called in creation order, whenever a navigation is triggered.
 * Guards may be resolved asynchronously, and the navigation is considered pending before
 * all hooks have been resolved.
 * @param to Target Route Object being navigated to
 * @param from Current route being navigated away from
 * @param next Must be called to resolve hook
 */
export function onBeforeEach(
    to: OBR.Router.Route,
    from: OBR.Router.Route,
    next: (to?: OBR.Router.RouteLocationRaw | false | void) => void
): void {
    const loggedIn = userStoreService.isLoggedIn()
    const redirectQueryParams = to.query?.[APP_QUERY_PARAM_REDIRECT]

    // Middleware for set redirect debug cookie to prevent iframe url redirection to host app
    if (isIframe() && ENV_RB === RBEnv.PRODUCTION) {
        // if rbredirect and the value is false set the localstorage value to false
        if (redirectQueryParams && redirectQueryParams === 'false') {
            setItem(STORAGE_KEY_HR_REDIRECT, 'false')
        } else if (redirectQueryParams && redirectQueryParams === 'true') {
            setItem(STORAGE_KEY_HR_REDIRECT, 'true')
        }

        const isTestURL = APP_TEST_URLS.some((host) =>
            window.location.hostname.includes(host)
        )

        // redirect iframe url to host app (unless redirect cookie is set)
        if (
            !isTestURL &&
            window.self === window.top &&
            getItem(STORAGE_KEY_HR_REDIRECT) !== 'false'
        ) {
            const lang = userStoreService.language()
            const hostURI = getHostUri(lang)

            window.location.href = `${hostURI}${to.fullPath}`

            return
        }
    }

    // if top menu is visible and page is different of home, hide it
    if (uiStoreService.isLeftMenuVisible() && to.name !== 'home') {
        uiStoreService.setLeftMenuVisible(false)
    }

    /**
     * Betsafe specific route guard
     * i.e. /en/horse-racing/extras/today-highlights    -> /en/horse-racing/highligh
     * i.e. /en/horse-racing/bets                       -> /en/horse-racing/my-bets
     * i.e. /en/horse-racing/upcoming                   -> /en/horse-racing
     * i.e. /en/horse-racing/extras                     -> /en/horse-racing with menu open
     */
    if (to.path.endsWith('/extras/today-highlights')) {
        next('/highlights')
        return
    } else if (to.path.endsWith('/bets')) {
        next('/my-bets')
        return
    } else if (to.path.endsWith('/upcoming')) {
        next('/')
        return
    } else if (to.path.endsWith('/extras')) {
        next('/')
        uiStoreService.setLeftMenuVisible(true)
        return
    } else if (
        to.path.endsWith('/livestreams') ||
        to.redirectedFrom?.fullPath.endsWith('/livestreams')
    ) {
        onOpen()
    }

    // Auth Guard
    if (to.matched.some((record) => record.meta.auth === true) && !loggedIn) {
        next({ name: 'login' })

        if (to.name) {
            appStoreService.setRedirect({
                name: to.name,
                params: to.params,
                query: to.query,
            })
        }

        return
    }

    // Visible Route Guard
    if (to.matched.some((record) => record.meta.visible === false)) {
        next('/')
        return
    }

    // Get i18n modules required by all matching routes
    const locale = userStoreService.language()
    const i18nService = I18nService.getInstance(locale)
    const i18nModulesRequired = i18nService.getRequiredModules(
        getMatchedModules(to.matched)
    )

    if (i18nModulesRequired.length > 0) {
        i18nStoreService
            .loadModules({ modules: i18nModulesRequired })
            .then(() => {
                next()
            })
    } else {
        next()
    }
}

/**
 * Similar to `router.beforeEach`, with the difference that resolve guards will be called
 * right before the navigation is confirmed, after all in-component guards and async route
 * components are resolved
 * @param to Target Route Object being navigated to
 * @param from Current route being navigated away from
 * @param next Must be called to resolve hook
 */
export function onBeforeResolve(
    to: OBR.Router.Route,
    from: OBR.Router.Route,
    next: (to?: OBR.Router.RouteLocationRaw | false | void) => void
): void {
    // for getting current path from store use
    // TODO: add solution for store
    // TagManager[ROUTE_CHANGE](to.fullPath, document.title)
    // updateMetaTitle(to.meta.title, to.fullPath)
    next()
}

/**
 * Router hook after each routing event
 * @param to Target Route Object being navigated to - type OBR.Router.Route
 * @param from Current route being navigated away from - type OBR.Router.Route
 */
export function onAfterEach() {
    // Focus h1 after route change
    const h1 = OBRDocument().querySelector('h1')
    if (h1) {
        h1.focus()
    }

    // if (ENV_RB === RBEnv.LOCAL_DEVELOP) {
    //     const isTimeTraveling: boolean | null = (window as any)[TIME_TRAVELING]
    //     if (isTimeTraveling) {
    //         (window as any)[TIME_TRAVELING] = false
    //         return
    //     }
    //     (window as any)[CURRENT_PATH] = to[ROUTER_FULL_PATH_KEY]
    // }
    // store.dispatch(`router/${ROUTE_CHANGE_SUCCESS}`, { to, from })
}

/**
 * On route error callback. Triggered in case of these scenarios:
 *  - The error is thrown synchronously inside a route guard function
 *  - The error is caught and asynchronously handled by calling next(err) inside a
 *    route guard function
 *  - An error occurred when trying to resolve an async component that is required
 *    to render a route
 * @param callback
 */
export function onError(/* err: Error */): void {
    //
}

export function errorLoadingFile(error: Error): void {
    if (
        error.message.includes('Failed to fetch dynamically imported module') ||
        error.message.includes("Couldn't resolve component")
    ) {
        getReleaseInfo()
    } else {
        throw error
    }
}

/**
 * Router hook on success
 * @param router VueRouter
 */
export function onSuccess(router: OBR.Router.VueRouter) {
    return () => {
        router.afterEach(({ path }: OBR.Router.Route) => {
            setItem(STORAGE_KEY_CURRENT_ROUTE, {
                locale: userStoreService.language(),
                path,
            })
        })
    }
}
